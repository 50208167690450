@font-face {
  font-family: "Calibri";
  src: url("./font/Calibri\ Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("./font/Calibri\ Bold.TTF") format("truetype");
  font-weight: bold;
  font-style: normal;
}
body {
  font-family: "Mulish", sans-serif !important;
  font-size: 0.9vmax !important;
  letter-spacing: 0.5px;
  overflow: hidden;
}
.t-a-Left td {
  text-align: left !important;
  color: black !important;
}
::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 10px;
}
a {
  text-decoration: none !important;
}
.laptop {
  display: block;
}
.mobile {
  display: none;
}
textarea {
  height: 80px !important;
  overflow: auto !important;
}
:focus-visible {
  outline: none !important;
}
/* basemodal background color */
.react-confirm-alert-overlay {
  background: rgb(9 9 9 / 49%) !important;
}
/* //html editor height */
.ck-editor__editable_inline {
  max-height: 180px;
}
/* mui autocomplete size */
.MuiInputBase-input {
  /* font-size: 0.7rem !important; */
}
/* html text editor height */
.ck-editor__editable_inline {
  min-height: 200px;
}
/* resume style */
.pg-viewer-wrapper .document-container {
  width: auto !important;
}
.pg-viewer-wrapper #docx {
  width: 100% !important;
  height: 80vh !important;
}
p {
  margin-bottom: 0px !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 0.7rem !important;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}
.tablecoming {
  word-break: inherit !important;
  text-transform: initial !important;
  font-weight: initial !important;
  border: 1px solid lightgray !important;
  padding: 4px 8px !important;
  border-radius: 5px !important;
}
.tablecoming table {
  width: 100%;
}
@media screen and (max-width: 960px) {
  .laptop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
.css-q23d56-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #142130 !important;
}
html,
body {
  touch-action: none;
}
.iframeContainer {
  width: 100% !important;
  border: 0;
  height: 75vh !important;
  margin-top: 10px;
}
.MuiInputLabel-root {
  font-size: 0.7rem !important;
  /* padding-bottom: 20px !important; */
}

/* .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: 0 !important;
  padding: 3px !important;
} */
.MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
  height: 0 !important;
  padding: 3px !important;
}
.popup-overlay {
  background-color: rgba(0, 0, 0, 0.7);
}
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.7);
}
.MuiAutocomplete-popper {
  font-size: 0.7rem !important;
}
ul li {
  font-size: 0.7rem !important;
  padding: 4px 8px !important;
}
.tablecoming ul li {
  font-size: 1vmax !important;
}
.MuiOutlinedInput-notchedOutline {
  border: 1px solid lightgray !important;
}
.selectMultiple .MuiOutlinedInput-notchedOutline {
  border: none !important;
  padding: 2px;
}
.selectMultiple
  .MuiSelect-select
  .MuiSelect-outlined
  .MuiSelect-multiple
  .MuiInputBase-input
  .MuiOutlinedInput-input {
  padding: 1px 5px !important;
  /* width: 200px !important; */
}
.selectMultiple::-webkit-scrollbar {
  width: 1px;
  border-radius: 1px;
}
input {
  width: 100%;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid lightgray;
  &:focus-visible {
    border: 1px solid grey;
  }
}
select {
  width: 100%;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid lightgray;
  &:focus-visible {
    border: 1px solid grey;
  }
}
label {
  margin-top: 5px;
}
.switchLabel label {
  margin: 0px;
}
.password-toggle-icon {
  position: absolute;
  top: 68%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.password-toggle-icon {
  /* font-size: 0.7rem; */
  /* line-height: 1; */
  color: grey;
  transition: color 0.3s ease-in-out;
  /* margin-bottom: 20px; */
}
.user-box {
  position: relative;
  input {
    padding: 0.55em 1em !important;
  }
  /* margin-bottom: 30px; */
}
.icon {
  /* padding-left: 20px;
  background: url("./assets/images/dollarIcon.svg") no-repeat left;
  background-size: 12px;
  background-position-x: 4px; */
}
span.MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-594.MuiSwitch-switchBase.MuiSwitch-colorSecondary {
  overflow: hidden;
  width: 90px;
  margin-right: 10px !important;
}
span.MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-606.MuiSwitch-switchBase.MuiSwitch-colorSecondary.PrivateSwitchBase-checked-607.Mui-checked {
  margin-left: 10px !important;
  overflow: hidden;
  margin-right: 0px !important;
  width: 90px;
}
hr {
  opacity: 0.15;
  border: 0px solid lightgray !important;
  margin: 10px 0 !important;
}
.jobDetailTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.flex {
  display: flex;
}
.MuiAutocomplete-inputRoot input {
  margin: 0 !important;
  outline: 0 !important;
  line-height: 1.21428571em !important;
  padding: 0.46em 1em !important;
  font-size: 1em !important;
  background: #fff !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border-radius: 0.28571429rem !important;
  box-shadow: 0 0 0 0 transparent inset !important;
  transition: color 0.1s ease, border-color 0.1s ease !important;
  /* width: 100% !important;
  padding: 5px !important;
  border-radius: 4px !important;
  background-color: white;
  border: 1px solid lightgray !important;
  &:focus-visible {
    border: 1px solid grey !important;
  } */
}
.MuiAutocomplete-inputRoot .MuiOutlinedInput-root {
  padding: 0 !important;
}
.MuiOutlinedInput-notchedOutline {
  display: none !important;
  padding: 0 !important;
}
.MuiOutlinedInput-root {
  padding: 0 !important;
}
.MuiAutocomplete-endAdornment button:first-child {
  display: none !important;
}
.MuiAutocomplete-endAdornment svg {
  width: 15px !important;
  height: 15px !important;
  width: 10px;
  height: 10px;
  right: 20px !important;
  top: 10px !important;
}
/* button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
  display: none !important;
} */

button {
  text-transform: capitalize !important;
}
.searchForSelect {
  position: absolute;
  right: 10px;
  top: 5px;
}
/* 
th {
  position: sticky !important;
  top: 0 !important;
  background-color: #1a202c !important;
  z-index: 10 !important;
  padding: 10px !important;
  text-align: left !important;
  white-space: nowrap !important;
  color: white; 
} */
.ReportTable th {
  top: -10px !important;
}
.commentContainer button {
  padding: 4px 10px !important;
  margin-left: 6px!important;
  margin-right: 0px !important;
  margin-top: 0px !important;
}
.date input {
  border: 1px solid lightgrey !important;
  padding: 4px;
  /* margin-top: 8px; */
}
.date button:hover {
  background-color: transparent;
}
.date .css-1laqsz7-MuiInputAdornment-root {
  margin-left: 0 !important;
}
.date button {
  right: 18px;
  position: absolute;
}
.date button svg {
  width: 15px;
  height: 15px;
  /* margin-top: 8px; */
}
.date span {
  display: none;
}
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.mb-1 {
  margin-bottom: 10px !important;
}
.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 200px;
  height: 140px;
  box-sizing: border-box;
}
.menuItem {
  padding: 5px 10px;
}
/* src/components/FAQ.css */
.faq-item {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  border-radius: 4px;
}

.faq-question {
  padding: 15px;
  cursor: pointer;
  /* background-color: #f9f9f9; */
  font-weight: bold;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #006599;
}
.faq-question div {
  margin-right: 10px;
}

.faq-answer {
  padding: 15px;
  padding-top: 0;
  padding-left: 30px;
  /* border-top: 1px solid #ddd; */
  /* background-color: #fff; */
}
.mb-5 {
  margin-bottom: 10px;
}
.ui.form.segment {
  padding: 0 !important;
  box-shadow: none !important;
  border: none !important;
}
.ui.dropdown > .dropdown.icon {
  background: none !important;
}
.ui.selection.dropdown {
  width: 100% !important;
}
.field {
  width: 100% !important;
}
.ui.small.input {
  width: 100% !important;
}
.tootTipTable th {
  border: 1px solid;
  padding: 5px;
}
.tootTipTable td {
  border: 1px solid;
  padding: 5px;
}
.ui.disabled {
  border: 1px solid lightgray !important;
  border-radius: 5px;
}
#recruiterSelect {
  /* z-index: 100 !important;
  position: absolute !important;
  width: 100px !important; */
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4px !important;
}
.ui.toggle.checkbox label {
  margin-top: 0px !important;
}
.ui.menu {
  margin-bottom: 0px !important;
}
.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
  width: 1.2rem !important;
  height: 1.2rem !important;
}
.ui.toggle.checkbox .box::before,
.ui.toggle.checkbox label:before {
  width: 3rem !important;
  height: 1.2rem !important;
}
.sl-spinner2 {
  height: 35px;
}
textarea {
  width: 100%;
  border: 1px solid lightgray;
}
.ui.selection.dropdown {
  min-height: auto !important;
  line-height: 0.8em !important;
}
.ui.attached.segment {
  margin: 0 !important;
}
.ui.segment,
.ui.segments .segment {
  font-size: 12px !important;
}
figure {
  margin: 0 !important;
}
.custom-dropdown .menu .item {
  font-size: 12px !important;
}
.ui .dropdown .active.icon {
  color: #2185d0 !important;
}
